import React, { useState, useEffect } from 'react';
import './Popup.css'; // Create a CSS file for styles
import axios from 'axios';

const Popup = () => {
    const [isOpen, setIsOpen] = useState(false);
    // const [email, setEmail] = useState('');
    // const [isEmailValid, setIsEmailValid] = useState(false);
    const [error, setError] = useState(''); // For error messages

    useEffect(() => {
        const hasSeenPopup = localStorage.getItem('hasSeenPopup');
        if (!hasSeenPopup) {
            setIsOpen(true); // Show the popup if not seen
        }
    }, []);


    const closePopup = async () => {
        // if (isEmailValid) {
        //     // Send email to backend before closing the popup
        //     try {
        //         await sendEmailToBackend();
        //         setIsOpen(false);
        //         localStorage.setItem('hasSeenPopup', 'true'); // Mark as seen
        //     } catch (error) {
        //         setError('Failed to send email. Please try again.');
        //         console.error(error);
        //     }
        // } else {
        //     setIsOpen(false); // Close popup if email is not valid
        //     localStorage.setItem('hasSeenPopup', 'true'); // Mark as seen
        // }
        setIsOpen(false); // Just close the popup without email validation
        localStorage.setItem('hasSeenPopup', 'true'); // Mark as seen
    };


    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            closePopup(); // Call closePopup when Enter is pressed
        }
    };

    const handleOverlayClick = (e) => {
        if (e.target.classList.contains('overlay')) {
            closePopup();
        }
    };

    return (
        isOpen && (
            <div className="overlay" onClick={handleOverlayClick}>
                <div className="popup">
                    <h2>Welcome to Worksheep!</h2>
                    <p>We're thrilled to introduce exciting new features and improvements to help you create custom worksheets in no time!</p>
                    <p><strong>For the best experience, we recommend using a PC.</strong></p>
                    <p>With Worksheep, you can easily create, import, and customize Math worksheets. Choose topics, set difficulty levels, and align content with curriculum standards—all on our intuitive platform.</p>
                    <p>Any questions? Reach out at <a href="mailto:hello@theworksheep.com">hello@theworksheep.com</a>!</p>
                    {/* <input 
                        type="email" 
                        placeholder="Enter your email to continue" 
                        value={email}
                        onChange={handleEmailChange}
                        onKeyDown={handleKeyDown} // Add key down handler
                        required
                    />
                    {error && <p style={{ color: 'red' }}>{error}</p>} {/* Error message */}
                    {/* <button onClick={closePopup} disabled={!isEmailValid}>
                        Continue
                    </button> */}
                    <button onClick={closePopup}>
                        Continue
                    </button>
                </div>
            </div>
        )
    );
};

export default Popup;
