import React, { useState, useEffect, useCallback } from 'react';
import { pdf } from '@react-pdf/renderer';
import axios from 'axios';
import Geometrypdf from '../pdfdoc/geometrytemp';
import { toPng } from 'html-to-image';
import GeomtryDifficulty from './GeometryDifficulty/GeometryDifficulty';
import './Geometryqs.css'

const Geometryqs = () => {

    const [svgUrls, setSvgUrls] = useState([]); // Store multiple SVG URLs
    const [pngDataUrls, setPngDataUrls] = useState([]); // Store multiple PNG URLs
    const [loading, setLoading] = useState(false);
    const [prompt, setPrompt] = useState('');
    const [error, setError] = useState('');
    const [apiResposne, setapiResponse ] = useState('')
    const [selectedDifficulty, setselectedDifficulty] = useState('')
    



    const generateShapeResponse = async (prompt) => { 
        setLoading(true);
        setError('');
     

        try {
          const response = await axios.post(`${process.env.REACT_APP_BE_URL}/shapesgenerate`, {
            prompt:prompt,
            model: 'llama3-8b-8192', 
          });
      
          setapiResponse(response.data);
          
        

          const RecievedpngUrls = await captureSvgAsImages(response.data.svgUrls);
     
          
         
          setPngDataUrls(RecievedpngUrls)
          
        } catch (error) {
          console.error('Error generating content and processing data:', error);
          setError('Error generating content and processing data. Please try again.');
        } finally {
          setLoading(false);
        }
      };



    useEffect(() => {
        const generatePDF = async () => {
            if (pngDataUrls.length > 0) {
                try {
                    const pdfBlob = await pdf(<Geometrypdf svgUrls={pngDataUrls} />).toBlob();
                    const pdfUrl = URL.createObjectURL(pdfBlob);
                    window.open(pdfUrl, '_blank');
                    setLoading(false);
                } catch (error) {
                    console.error("Error generating PDF:", error);
                    setLoading(false);
                }
            }
        };
    
        generatePDF(); // Call the async function
    }, [pngDataUrls]);

    const captureSvgAsImages = async (svgUrls) => {
        if (!Array.isArray(svgUrls) || svgUrls.length === 0) {
            console.error("SVG URLs are invalid or empty:", svgUrls);
            return [];
        }
    
        const capturePromises = svgUrls.map((svgUrl) => {
            return new Promise((resolve, reject) => {
                if (!svgUrl) {
                    console.error("Skipping invalid SVG URL:", svgUrl);
                    reject(new Error("Invalid SVG URL"));
                    return;
                }
    
                const img = new Image();
                img.src = svgUrl;
    
                img.onload = async () => {
                    const div = document.createElement('div');
                    div.style.width = '500px';
                    div.style.height = '500px';
                    div.style.backgroundImage = `url(${svgUrl})`;
                    div.style.backgroundSize = 'auto';
                    div.style.visibility = 'visible';
                    document.body.appendChild(div);
    
                    try {
                        const pngUrl = await toPng(div);
                        resolve(pngUrl);
                    } catch (err) {
                        console.error("Error capturing SVG as PNG:", err);
                        reject(err);
                    } finally {
                        document.body.removeChild(div);
                    }
                };
    
                img.onerror = () => {
                    console.error("Error loading SVG image:", svgUrl);
                    reject(new Error(`Failed to load SVG: ${svgUrl}`));
                };
            });
        });
    
        try {
            const funcPngUrls = await Promise.all(capturePromises);
            return funcPngUrls;
        } catch (err) {
            console.error("Error capturing one or more SVGs:", err);
            return [];
        }
    };









   
    const handleDifficultyClick = (description) => {
        setselectedDifficulty(description); 
        
        const statusText = `
            Generate a list of 5 closed triangles and 5 quadrilaterals in JSON format, with the following properties and a specified difficulty level for the unknown angle:
            type: Either "triangle" or "quadrilateral."
            vertices: Coordinates for the vertices, where each coordinate is a pair of x and y values between 0 and 490, ensuring the shapes are large and extend close to or span the edges of the canvas. Example: [{"x": 1, "y": 1}, {"x": 490, "y": 1}, {"x": 250, "y": 490}].
            angles: A list of internal angles in degrees, ensuring the sum matches the polygon's total angle sum (e.g., 180 for a triangle, 360 for a quadrilateral). One of the angles is unknown, represented by a question mark like this "?".
            The known angles should align with the specified difficulty:
            ${description}
            color: A #FFFFFF fill color.
            strokeColor: A #000000 stroke color.
            strokeWidth: A width of 2.
            Ensure the triangles and quadrilaterals have varied shapes and make effective use of the 500x500 canvas for visibility.
        `;
        
        setselectedDifficulty(description);
        setPrompt(statusText);
        console.log(statusText); 
    };

    const handleGenerateClick = () => {
        if (!selectedDifficulty) {
            setError('Please select a difficulty level before generating shapes.');
            return;
        }
        generateShapeResponse(prompt);
    };



    return (
        <div>

<div>
    <GeomtryDifficulty 
            onClick={handleDifficultyClick} 
            selectedDifficulty={selectedDifficulty} 
    
    />
</div>


<button
                className='generate-button'
                onClick={handleGenerateClick}
                disabled={loading}
            >
                Generate &#10024;
            </button>
            
            {error && <div className="error-message">{error}</div>} 
            {loading && <div className="loader">Loading...</div>} 
        </div>
    );
};

export default Geometryqs;