import React, { useState, useEffect } from 'react';
import './TemplateCarousel.css';
import temp1 from '../../assets/TEMPLATE 1.png'
import temp2 from '../../assets/TEMPLATE 2.png'
import temp3 from '../../assets/TEMPLATE 3.png'
import Joyride from 'react-joyride';
import {TemplateCarouselStep} from '../../data/joyrideSteps.js'



const TemplateCarousel = ({ onSelectTemplate }) => {
  // Array of template options
  const templates = [
    {
      id: 'exam-style',
      name: 'Exam Style Revision',
      description: 'Individual rows with single questions and some answering space',
      image: temp1,
    },
    {
      id: 'column-template',
      name: 'Column Operations',
      description: 'Column addition, subtraction or short multiplication exercises',
      image: temp2,
    },
    {
      id: 'geometry-template',
      name: 'Geometry - Interior Angles',
      description: 'Triangles and Quadrilaterals with one missing angle',
      image: temp3,
    },
  ];

 // Track the current template index for the carousel
 const [currentIndex, setCurrentIndex] = useState(0);
 const [run, setRun] = useState(false);





 // Handlers for next/prev buttons
 const handleNext = () => {
   setCurrentIndex((prevIndex) =>
     prevIndex === templates.length - 1 ? 0 : prevIndex + 1
   );
 };

 const handlePrev = () => {
   setCurrentIndex((prevIndex) =>
     prevIndex === 0 ? templates.length - 1 : prevIndex - 1
   );
 };

  //New Users Pop Up
  useEffect(() => {
    // Check if the user has seen the guide before
    const hasVisitedBefore = localStorage.getItem('hasVisited2');
    
    if (!hasVisitedBefore) {
      // If not, set run to true to show the guide
      setRun(true);
      // Save a flag in localStorage so the guide doesn't show again
      localStorage.setItem('hasVisited2', 'true');
    }
  }, []);



 // Handler for template selection
 const handleTemplateClick = () => {
   const selectedTemplate = templates[currentIndex];
   onSelectTemplate(selectedTemplate.id); // Pass the selected template ID to the parent component
 };

 return (
    <div className="template-carousel-container">
            <Joyride
        steps={TemplateCarouselStep}
        run={run}
        continuous
        scrollToFirstStep
        showSkipButton
        styles={{
          options: {
            zIndex: 1000,
          },
        }}
      />
      <h3>Select a Template</h3>
      <div className="carousel">
       

        {/* Display the current template */}
        <button className="prev-button" onClick={handlePrev}>
          &larr;
        </button>
        <div className="template-card" onClick={handleTemplateClick}>
          <img
            src={templates[currentIndex].image}
            alt={templates[currentIndex].name}
            className="template-image"
          />
          <h3>{templates[currentIndex].name}</h3>
          <p>{templates[currentIndex].description}</p>
          <button className="next-button" onClick={handleNext}>
          &rarr;
        </button>
        </div>

        <button className="next-button" onClick={handleNext}>
          &rarr;
        </button>
      </div>
    </div>
  );
};

export default TemplateCarousel;