import '../GeometryDifficulty/GeometryDifficulty.css';

const GeometryDifficulty = ({ onClick, selectedDifficulty }) => {
  const difficultyData = {
    Easy: 'Simple Triangles and Rectangles with rounded numbers (e.g., 90°, 45°)',
    Medium: 'Varied angles and shapes like Rhombus, Kites, Trapeziums with decimal numbers',
    Hard: 'Challenging values requiring detailed arithmetic',
  };

  return (
    <div>
       <a href="https://forms.gle/7BjRTveXhiVnxAme8" target="_blank" rel="noopener noreferrer" className='beta_container'>
    <span className='beta_label'>BETA MODE</span>
</a>
    <div className="geo-difficulty-box">
 
      {Object.entries(difficultyData).map(([difficulty, description], index) => (
        <div key={index} className="difficulty-item">
          <button
            onClick={() => onClick(description)}
            className={`geo-difficulty-button ${
              selectedDifficulty === description ? 'active' : ''
            }`}
          >
            {difficulty}
          </button>
          <label className="geo-difficulty-label">{description}</label>
        </div>
      ))}
    </div>
    </div>
  );
};

export default GeometryDifficulty;
