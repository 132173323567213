import React, { useState } from "react";
import './navbar.css';
import Logo from '../Navbar/sheep.svg';
import Tooltip from '@mui/material/Tooltip';
import { useNavigate } from 'react-router-dom';

import Facebook from '../../assets/hamburgermenu_icons/facebook.svg';
import Instagram from '../../assets/hamburgermenu_icons/instagram.svg';
import Linkedin from '../../assets/hamburgermenu_icons/linkedin.svg';
import tiktok from '../../assets/hamburgermenu_icons/tiktok.svg';
import home from '../../assets/hamburgermenu_icons/home.svg';
import blogs from '../../assets/hamburgermenu_icons/blogs.svg';
import tuts from '../../assets/hamburgermenu_icons/tutorials.svg';
import feedback from '../../assets/hamburgermenu_icons/feedback.svg';
import logout from '../../assets/hamburgermenu_icons/logout.svg';

const Navbar = ({ onMenuToggle }) => {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [availableWorksheets, setAvailableWorksheets] = useState(5);

  const navigate = useNavigate();

  const handleLogoutClick = (event) => {
    event.preventDefault(); 
    console.log("Logging out...");
    navigate('/'); 
  };

  const toggleMenu = () => {
    const newState = !isMenuOpen;
    setMenuOpen(newState);
    onMenuToggle(newState); // Notify Layout about the menu state
};


  return (
  <div className='navbar'>
      <div className='navbar_logo-title'>
      <button className="hamburger" onClick={toggleMenu}>
                    ☰
                </button>
        <img src={Logo} alt='Logo' className='navbar_logo' />
        <h1 className='navbar_title'>Worksheep</h1>
        {/* <div className='beta_container'>
          <span className='beta_label'>BETA</span>
        </div> */}
      </div>

    {/* <div className='worksheet_counter'>
        Worksheets Available:<span className='counter_value'>{availableWorksheets}</span>
    </div> */}



    <div className={`hamburger-menu ${isMenuOpen ? "open" : ""}`}>
  <ul className="menu-list">
    {/* Group 1 */}
    <div className="menu-group">
      <li className='menu-item'>
      <img src={home} alt="home" className="menu-icon" />
        <a href='https://www.theworksheep.com/' target='_blank' rel='noopener noreferrer'>
          <i className="icon-home"></i> Home
        </a>
      </li>
      <li className='menu-item'>
      <img src={blogs} alt="Instagram" className="menu-icon" />
        <a href='https://www.theworksheep.com/blogs'target='_blank' rel='noopener noreferrer'>
          <i className="icon-item1"></i> Blogs
        </a>
      </li>
    </div>
    
    <hr className="menu-divider" />
    
    {/* Group 2 */}
    <div className="menu-group">
      <li className='menu-item'>
      <img src={tuts} alt="tutorials" className="menu-icon" />
        <a href='https://www.youtube.com/watch?v=co4koppeTUs&t=7s' target='_blank' rel='noopener noreferrer'>
          <i className="icon-tutorials"></i> Tutorials
        </a>
      </li>
      <li className='menu-item'>
      <img src={feedback} alt="tutorials" className="menu-icon" />
        <a href='https://forms.gle/iTGhS8mc2CCreumB7' target='_blank' rel='noopener noreferrer'>
          <i className="icon-feedback"></i> Feedback
        </a>
      </li>
    </div>
    
    <hr className="menu-divider" />
    
    {/* Group 3 */}
    <div className="menu-group menu-group-bottom">
      <li className='menu-item'>
      <img src={logout} alt="tutorials" className="menu-icon" />
        <a onClick={handleLogoutClick}>
          <i className="icon-logout"></i> Logout
        </a>
      </li>
      <li className='social-icons'>
        <a href='https://www.linkedin.com/company/theworksheep' target='_blank' rel='noopener noreferrer'>
          <img src={Linkedin} alt="Linkedin" className="menu-icon" />
        </a>
        <a href='https://www.facebook.com/profile.php?id=61563133166252' target='_blank' rel='noopener noreferrer'>
          <img src={Facebook} alt="Facebook" className="menu-icon" />
        </a>
        <a href='https://www.instagram.com/theworksheeeeeep?fbclid=IwY2xjawFzaj1leHRuA2FlbQIxMAABHdFJIXhStu1eO_QuR0RGUc-IpJOYWz_MnBgII81cCJFlMFQdVbtZCz96Gw_aem_aZqAMr2ShhC1y3YlatZIqg' target='_blank' rel='noopener noreferrer'>
          <img src={Instagram} alt="Instagram" className="menu-icon" />
        </a>
      </li>
    </div>
  </ul>
</div>




    
  </div>
  );
}

export default Navbar;