import { useContext, useEffect, useState } from "react";
import './aiprompt.css';
import Yeargroupbuttons from "../YearGroup/Yeargroup";
import Difficlutybuttons from '../Difficulty/difficulty';
import SubjectsLibrary from "../Subjects/Subject";

import Joyride from 'react-joyride';
import { Template1Steps } from "../../data/joyrideSteps.js";

import { Context } from "../Layout/Layout";

const backendUrl = process.env.REACT_APP_BE_URL;


export default function AiGenerate({ generateContentAndPdf }) {
  const { selectedSubjects, setSelectedSubjects, textbox1, setTextbox1 } = useContext(Context);
  const [prompt, setPrompt] = useState(null);
  const [selectedYear, setSelectedYear] = useState(null);
  const [open, setOpen] = useState(false);
  const [textbox2, setTextbox2] = useState('');
  const [difficulty, setDifficulty] = useState(null);
  const [run, setRun] = useState(false);


  useEffect(() => {
    handleContinentClick();
  }, [selectedSubjects, selectedYear, textbox2, textbox1, difficulty]);

  const [errors, setErrors] = useState({
    nameError: '',
    countError: '',
    yearError: '',
    difficultyError: '',
    subjectError: ''
  });

  const validateInputs = () => {
    const newErrors = {
      nameError: '',
      countError: '',
      yearError: '',
      difficultyError: '',
      subjectError: ''
    };
  
    if (!textbox1 || textbox1.trim() === '') {
      newErrors.nameError = 'Worksheet Name is required.';
    }
    if (!textbox2 || textbox2.trim() === '') {
      newErrors.countError = 'Question Count is required.';
    }
    if (!selectedYear) {
      newErrors.yearError = 'Year group selection is required.';
    }
    if (!difficulty) {
      newErrors.difficultyError = 'Difficulty selection is required.';
    }
    if (!selectedSubjects || selectedSubjects.length === 0) {
      newErrors.subjectError = 'At least one lesson must be selected.';
    }
  
    setErrors(newErrors);
  
    // Return whether there are any errors
    return Object.values(newErrors).every(error => error === '');
  };



  //New Users Pop Up
  useEffect(() => {
    // Check if the user has seen the guide before
    const hasVisitedBefore = localStorage.getItem('hasVisited');
    
    if (!hasVisitedBefore) {
      // If not, set run to true to show the guide
      setRun(true);
      // Save a flag in localStorage so the guide doesn't show again
      localStorage.setItem('hasVisited', 'true');
    }
  }, []);


  const submitHandler = (e) => {
    if (validateInputs()) {
    e.preventDefault();
    generateContentAndPdf(prompt);
  } else {
    // Error messages will be displayed based on the state
  }
};

  const handleYearClick = (year) => {
    setSelectedYear(year);
    // Clear the error if a year is selected
  if (year !== null) {
    setErrors((prevErrors) => ({
      ...prevErrors,
      yearError: ''
    }));
  }
  };

  const handleDifficulty = (difficulty) => {
    setDifficulty(difficulty);
    // Clear the error if a difficulty is selected
  if (difficulty !== null) {
    setErrors((prevErrors) => ({
      ...prevErrors,
      difficultyError: ''
    }));
  }
    
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSelect = (subjects) => {
    setSelectedSubjects(subjects);
    if (subjects && subjects.length > 0) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        subjectError: ''
      }));
    }
  };

  const handleContinentClick = () => {
    const statusText = `Please provide exactly ${textbox2} ${difficulty} -level questions on the following mathematics topics for  ${selectedYear} students in the UK curriculum. The topics are: ${selectedSubjects}. 
    Please list only the questions without any additional text or explanations.
   Each question should include a corresponding answer listed in a separate section titled 'Answers', directly following the list of questions.
    Ensure that each question is :
    Uses the \\( ... \\) for inline LaTeX instead of the dollar signs for rendering LaTeX in web environments
    Purely mathematical and should be formatted using MathJax.
    Any questions involving multiplication, the multiplication sign is explicitly used and not the dot

    
    
    
    
    `
    setPrompt(statusText);
  };

  const changeone = (event) => {
    setTextbox1(event.target.value);
    const name = event.target.value;
    if (name.trim() !== '') {
      setErrors((prevErrors) => ({
        ...prevErrors,
        nameError: ''
      }));
    }
  };

  const changetwo = (e) => {
    const value = e.target.value;
    if (value <= 30) {
      setTextbox2(value);

      // Clear the error if the field is filled
  if (value.trim() !== '') {
    setErrors((prevErrors) => ({
      ...prevErrors,
      countError: ''
    }));
  }
    }
  };

  return (
    <div>
      <section>
      <Joyride
        steps={Template1Steps}
        run={run}
        continuous
        scrollToFirstStep
        showSkipButton
        styles={{
          options: {
            zIndex: 10000,
          },
        }}
      />
        <div className="basic_details">
          <input
            className="basic_details_name"
            placeholder="Worksheet Name"
            value={textbox1}
            onChange={changeone}
            type="text"
            id="item"
          />
          {errors.nameError && <p className="error">{errors.nameError}</p>}
          <input
            className="basic_details_count"
            placeholder="Question Count"
            value={textbox2}
            onChange={changetwo}
            type="number"
            id="item"
            max={30}
          />
          {errors.countError && <p className="error">{errors.countError}</p>}
        </div>
      </section>




      <section>
        <div className="yeargroup-buttons-div">
          <Yeargroupbuttons selectedYeargroup={selectedYear} onClick={handleYearClick} />
          {errors.yearError && <p className="error">{errors.yearError}</p>}
        </div>

        <div className="difficluty-buttons-div">
          <Difficlutybuttons selectedDifficulty={difficulty} onClick={handleDifficulty} />
        </div>
        {errors.difficultyError && <p className="error">{errors.difficultyError}</p>}
        
        
        <div className="subjects-library-div">
          <button 
          className="add-lessons-button"
          onClick={handleOpen} 
          variant="contained" 
          color="primary">
            Add Lessons
          </button>
          <SubjectsLibrary open={open} onSelect={handleSelect} onClose={handleClose} />
        </div>
        {errors.subjectError && <p className="error">{errors.subjectError}</p>}
      
      </section>
      


    {/* Generate Button   */}
      <div className="generate-button-div">
        <button 
        className="generate-button"
        onClick={submitHandler}>
          Generate &#10024;
        </button>
      </div>



    </div>
  );
}
