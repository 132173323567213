import React from 'react';
import { Document, Page, Text, Image, View, StyleSheet } from '@react-pdf/renderer';
import logo from '../../assets/logo192.png'

const styles = StyleSheet.create({
    body: { padding: 20 },
    header: { marginBottom: 20 },
    inputRow: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: 20,
    },
    inputBox: {
        width: '45%',
        padding: 10,
        borderWidth: 1,
        borderColor: '#ccc',
        fontSize: 12,
    },
    title: {
        fontSize: 15,
        textAlign: 'center', // Center the title
        marginBottom: 20,
    },
    row: {
        flexDirection: 'row',
        marginBottom: 20,
    },
    imageColumn: {
        width: '40%', // Left column for images
    },
    textColumn: {
        width: '60%', // Right column with additional space
        justifyContent: 'center',
        paddingLeft: 10,
        borderWidth: 1,
        borderColor: '#ccc',
        padding: 5,
    },
    image: {
        width: '100%',
        height: '150px', // Adjust height to fit neatly
        objectFit: 'contain',
        borderWidth: 1,
        borderColor: '#000',
    },
    placeholderText: {
        fontSize: 12,
        color: '#666',
    },
    footer: {
        position: 'absolute',
        bottom: 20,
        left: 20,
        right: 20,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        fontSize: 10,
    },
    logo: {
        width: 20,
        height: 20,
    },
});

const Geometrypdf = ({ svgUrls }) => (
    <Document>
        <Page style={styles.body}>
            {/* Header: Name and Score */}
            <View style={styles.header}>
                <View style={styles.inputRow}>
                    <Text style={styles.inputBox}>Name: </Text>
                    <Text style={styles.inputBox}>Score: </Text>
                </View>
                {/* Title */}
                <Text style={styles.title}>
                    Find the interior angles marked "?" in the shapes
                </Text>
            </View>

            {/* Content: Images and Placeholder */}
            {svgUrls.map((svgUrl, index) => (
                <View style={styles.row} key={index}>
                    <View style={styles.imageColumn}>
                        <Image src={svgUrl} style={styles.image} />
                    </View>
                    <View style={styles.textColumn}>
                        <Text style={styles.placeholderText}></Text>
                    </View>
                </View>
            ))}

            {/* Footer */}
            <View style={styles.footer}>
                <Text>Created via Worksheep</Text>
                <Image style={styles.logo} src={logo} />
            </View>
        </Page>
    </Document>
);

export default Geometrypdf;
