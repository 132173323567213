import React from 'react';
import { Page, Text, View, Document, Image, StyleSheet } from '@react-pdf/renderer';
import logo from '../../assets/logo192.png';

const styles = StyleSheet.create({
  page: {
    padding: 20,
    fontSize: 8.5,
  },
  title: {
    fontSize: 20,
    marginBottom: 10,
    textAlign: 'center',
  },
  studentInfoContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 10,
  },
  studentInfoBox: {
    borderWidth: 1,
    borderColor: '#000000',
    padding: 8,
    width: '45%',
  },
  tableRow: {
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderColor: '#000000',
  },
  cell: {
    flexGrow: 1,
    padding: 8,
    textAlign: 'left',
    verticalAlign: 'top',
    marginBottom: 30, // Add spacing below the question
  },
  image: {
    width: '550px',      // Set a fixed width
    height: 'auto',      // Maintain aspect ratio
    objectFit: 'contain',
    marginBottom: 40,
    padding: 8,
    alignSelf: 'flex-start'
  },
answerImage:{
  width: '450px',      
  height: 'auto',
},

  footer: {
    position: 'absolute',
    bottom: 20,
    left: 20,
    right: 20,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: 10,
  },
  logo: {
    width: 20,
    height: 20,
  },
  
  line: {
    borderBottomWidth: 1,
    borderColor: '#000000', // Line above the question
    marginBottom: 10,
  },
  textBox: {
    fontSize: 12,
    flex: 1,
  },


  answerSpace: {
    height: 40, // Space below each question for answers
    marginTop: 10,
  },
});


const MyDocument = ({ questions, textbox1 }) => {

  const chunkQuestions = (questions, chunkSize) => {
    const chunks = [];
    for (let i = 0; i < questions.length; i += chunkSize) {
      chunks.push(questions.slice(i, i + chunkSize));
    }
    return chunks;
  };

  // Separate questions and answers
  const questionChunks = chunkQuestions(questions.filter(q => q.type.includes('question')), 5);
  const answers = questions.filter(q => q.type.includes('answer'));

  return (
    <Document>
      {/* Render Question Pages */}
      {questionChunks.map((chunk, pageIndex) => (
        <Page key={pageIndex} style={styles.page}>
          {pageIndex === 0 && (
            <>
              <Text style={styles.title}>{textbox1}</Text>
              
              <View style={styles.studentInfoContainer}>
                <View style={styles.studentInfoBox}>
                  <Text>Student Name:</Text>
                </View>
                <View style={styles.studentInfoBox}>
                  <Text>Score:</Text>
                </View>
              </View>
            </>
          )}

          {/* Render Questions */}
          {chunk.map((item, index) => (
            <View key={index} style={styles.tableRow}>
              {item.type === 'text-question' ? (
                <Text style={styles.cell}>{item.content}</Text>
              ) : (
                <Image style={styles.image} src={item.content} />
              )}
            </View>
          ))}

          <View style={styles.footer}>
            <Text>Created via Worksheep</Text>
            <Image style={styles.logo} src={logo} />
          </View>
        </Page>
      ))}

      {/* Render Answers on a New Page */}
      {answers.length > 0 && (
        <Page style={styles.page}>
          <Text style={styles.title}>Answers</Text>

          {/* Render Answers as a List */}
          {answers.map((answer, index) => (
            <View key={index} style={styles.answerRow}>
              {answer.type === 'text-answer' ? (
                <Text style={styles.answerText}>{index + 1}. {answer.content}</Text>
              ) : (
                <Image style={styles.answerImage} src={answer.content} />
              )}
            </View>
          ))}

          <View style={styles.footer}>
            <Text>Created via Worksheep</Text>
            <Image style={styles.logo} src={logo} />
          </View>
        </Page>
      )}
    </Document>
  );
};

export default MyDocument;
