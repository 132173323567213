import React from 'react'
import supabase from '../../supabaseClient/supabaseClient'
import { Auth } from '@supabase/auth-ui-react'
import { ThemeSupa } from '@supabase/auth-ui-shared'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import './loginPage.css'
import Logo from '../Navbar/sheep.svg';

const Login = () => {


    const navigate = useNavigate();
    const [guestMode, setGuestMode] = useState(false); 
    const [showGuestLogin, setShowGuestLogin] = useState(false);
    const [guestUsername, setGuestUsername] = useState("");
    const [error, setError] = useState("");

    supabase.auth.onAuthStateChange(async(event) => {

        supabase.auth.onAuthStateChange(async (event) => {
            if (event == "SIGNED_IN") {
                navigate("/app");
            }
        });
    
    })


  const handleGuestLogin = async (e) => {
    e.preventDefault()

    if (!guestUsername.trim()) {
      alert("Please enter a username.");
      return;
    }

    if (!validateEmail(guestUsername)) {
      alert("Please enter a valid email address.");
      return;
    }

    console.log("Guest logged in as:", guestUsername); // Replace with real logic
    const {data, error} = await supabase
    .from('GuestLogin')
    .insert([ { guestemail: guestUsername }])
    .select()

    if (error) {
        console.log(error)

    }
    if (data) {
        navigate("/app");     
    }

    
  };





    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
      };


      return (
         <div className="login-page">
        <div className="login-header">
                <img src={Logo} alt="Worksheep_logo" className="worksheep-logo" />
                <h1 className="worksheep-heading">Worksheep</h1>
            </div>

            <div className="login-container">
                {!showGuestLogin ? (
                    <div className="auth-wrapper">
                        <Auth
                            supabaseClient={supabase}
                            appearance={{ theme: ThemeSupa }}
                            localization={{
                                variables: {
                                  sign_in: {
                                    email_label: '',
                                    password_label: '',
                                    email_input_placeholder:'Email Address',
                                    password_input_placeholder:' Password',
                                  },
                                  sign_up: {
                                    email_label: '',
                                    password_label: '',
                                    email_input_placeholder:'Email address',
                                    password_input_placeholder:' New Password',
                                  },
                                },
                              }}
                            providers={[]}
                        />
                        <button
                            onClick={() => setShowGuestLogin(true)}
                            className="guest-login-button"
                        >
                            Continue as a Guest 👋🏻
                        </button>
                    </div>

) : (
    <div className="guest-login-container">
        <div className="guest-login-input">

            <input
                type="email"
                id="guest-username"
                placeholder='Guest Email Address'
                value={guestUsername}
                onChange={(e) => setGuestUsername(e.target.value)}
                className="guest-input"
            />
        </div>
        <div className="guest-login-buttons">
            <button
                onClick={handleGuestLogin}
                className="guest-continue-button"
            >
                Continue
            </button>
            <button
                onClick={() => setShowGuestLogin(false)}
                className="guest-back-button"
            >
                Back
            </button>
        </div>
    </div>
)}
</div>
</div>
);
};

export default Login;
